import React, { useEffect, useState } from "react";
import { BasicLayout } from "../../layouts/BasicLayout";
import { getStripeUrlPaidTrial } from "../../actions/stripeAction";
import { navigate } from "gatsby";
import { saveUserOnboardingData } from "../../services/firebase";
import {
  web_acq_prepayment_attempt,
  web_acq_prepayment_failure,
  web_acq_prepayment_success,
  web_acq_prepayment_view,
} from "../../services/mixpanel/acquisition-events";
import { fbq } from "../../services/pixel";
import {
  Subscribe_Account_AccountCreationSuccess,
  setMixpanelProperties,
  trackEvent,
} from "../../services/mixpanel/mixpanel";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { baseUrl, BRAZE_API_KEY, BRAZE_SDK_ENDPOINT, laptopSize, mobileSize } from "../../util/variables";
import { triggerCustomEvent } from "../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../util/types";
import { useAuth } from "../../hooks/useAuth";
import { AppLoadingButton, AppText, AppTitle, FlexDiv } from "../../components/ui";
import theme from "../../util/theme";
import styled from "styled-components";
import { PAID_TRIAL_PRICE_IDS, UNIVERSAL_PRICE_IDS } from "../../util/local-price-ids";
import UpArrow from "../../content/img/icon/arrow-up-orange.svg";
import DigitalWalletIcon from "../../components/ui/DigitalWalletIcon";
import PrepaymentHeroImage from "../../components/PageSegments/special-offer/prepayment/PrepaymentHeroImage";

export const CHECKOUT_URL_KEY_PREFIX = "stripeCheckoutURL_paid_trial";

const PRICE_CARDS = [
  {
    price: 1,
    title: "$1",
  },
  {
    price: 7,
    title: "$7",
  },
  {
    price: 11.41,
    title: "$11.41*",
  },
];

export default (data: any) => {
  const priceId = "price_1Pqn4BLvyjRKGUTXFzFNdPM4";
  const [stripeURLs, setStripeURLs] = useState<Record<number, string>>({});

  const { user, loading: loadingUser } = useAuth();
  const [trackingComplete, setTrackingComplete] = useState(false);
  const [redirectClicked, setRedirectClicked] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(11.41);
  const { state } = data.location;
  const { onboardingAnswers } = useOnboardingContext();

  const redirectToStripe = (url: string) => {
    fbq("track", "InitiateCheckout");
    triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_SUCCESS);
    trackEvent("PaidTrial_CheckoutInitiated", { price: selectedPrice });
    web_acq_prepayment_success();
    setTimeout(() => {
      document.location.href = url;
    }, 100);
  };

  const handleRedirect = () => {
    web_acq_prepayment_attempt();
    setRedirectClicked(true);
  };

  const handlePriceChange = (price: number) => {
    trackEvent("PaidTrial_PriceChange", { price });
    setSelectedPrice(price);
  };

  // Post Account creation set up 3rd party tracking and analytics
  useEffect(() => {
    const trackNewUser = async (uid: string, email: string, onboardingAnswers: object) => {
      const universalControlGroup = Math.floor(Math.random() * 10) + 1;
      const storedUtm = localStorage.getItem("utmData");

      web_acq_prepayment_view();
      triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_VIEW);

      const promises = [
        import("../../services/braze").then(({ initialize, changeUser, getUser, openSession }) => {
          initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
          openSession();
          changeUser(uid);
          const user = getUser();
          if (user) {
            user.setEmail(email);
            user.setCustomUserAttribute("PrepaymentABPrice", priceId ?? "no price found");
            user.setCustomUserAttribute("customer_pool_id", universalControlGroup);
            console.log("Braze user and email has been set");
          }
        }),
        setMixpanelProperties({ AccountCreatedPlatform: "Web" }, true),
        Subscribe_Account_AccountCreationSuccess(email, uid),
        saveUserOnboardingData({
          uid,
          email,
          onboardingAnswers,
          userWebData: storedUtm ? JSON.parse(storedUtm) : {},
          universalControlGroup,
        }),
        fbq("track", "CompleteRegistration"),
      ];

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error("Error during user tracking:", error);
      } finally {
        setTrackingComplete(true);
      }
    };

    if (state?.uid && state?.email) {
      console.log("email", state.email);
      trackNewUser(state.uid, state.email, onboardingAnswers);
    } else {
      setTrackingComplete(true);
    }
  }, []);

  useEffect(() => {
    const getStripeURL = async () => {
      const price = selectedPrice ?? 11.41;
      const savedCheckoutUrl = sessionStorage.getItem(`${CHECKOUT_URL_KEY_PREFIX}_${price}`);
      if (savedCheckoutUrl) {
        setStripeURLs(prev => ({ ...prev, [price]: savedCheckoutUrl }));
        return;
      }

      try {
        const email = state?.email ?? user?.email;
        const uid = state?.uid ?? user?.uid;

        if (!email) {
          throw new Error("We require email in this flow to create a checkout session");
        }

        const stripeURL = await getStripeUrlPaidTrial({
          email,
          uid,
          cancel_url: `${baseUrl}/try`,
          success_url: `${baseUrl}/complete?session_id={CHECKOUT_SESSION_ID}&uid=${uid}&trial_price=${price}`,
          mode: "subscription",
          priceIds: [
            PAID_TRIAL_PRICE_IDS[price as keyof typeof PAID_TRIAL_PRICE_IDS],
            UNIVERSAL_PRICE_IDS[99.99],
          ],
          currency: "USD",
          subscription_data: { trial_period_days: 7 },
        });

        setStripeURLs(prev => ({ ...prev, [price]: stripeURL }));
        sessionStorage.setItem(`${CHECKOUT_URL_KEY_PREFIX}_${selectedPrice}`, stripeURL);
      } catch (err) {
        console.error(err);
        web_acq_prepayment_failure();
        // eslint-disable-next-line no-alert
        alert(
          "Something went wrong getting your checkout link. Please try again going through onboarding again.",
        );

        navigate("/get-started");
      }
    };

    if (!loadingUser) {
      getStripeURL();
    }
  }, [loadingUser, state, user, selectedPrice]);

  useEffect(() => {
    if (redirectClicked && selectedPrice && Boolean(stripeURLs[selectedPrice]) && trackingComplete) {
      redirectToStripe(stripeURLs[selectedPrice]);
    }
  }, [redirectClicked, stripeURLs, selectedPrice, trackingComplete]);

  return (
    <BasicLayout noIndex>
      <main style={{ backgroundColor: theme.WHITE_COLOR_2, minHeight: "100vh" }}>
        <Container>
          <AppTitle textAlign="center" style={{ maxWidth: "14ch" }}>
            Try for a week, pay what you want.
          </AppTitle>
          <AppText textAlign="center" style={{ maxWidth: "64ch" }}>
            We’re building a better way to learn. Our trial plans make Imprint accessible to everyone, while supporting the educators and artists who make it all possible.{" "}
            <DesktopBreak />
            <b style={{ color: theme.RED }}>Choose a trial plan you’re comfortable with.</b>
          </AppText>
          <CardsContainer>
            <FlexDiv gap={16}>
              {PRICE_CARDS.map(card => (
                <PriceCard key={card.title} selected={selectedPrice === card.price} onClick={() => handlePriceChange(card.price)}>
                  {card.title}
                </PriceCard>
              ))}
            </FlexDiv>

            <AppText fontSize={14} mobileFontSize={13} color={theme.SLATE_COLOR} style={{ display: "flex", marginTop: 20 }}>
              *This plan helps us support those who select the lowest price option
              <img src={UpArrow} height={26} width={40} style={{ rotate: "-20deg" }} />
            </AppText>
          </CardsContainer>
          <AppLoadingButton
            type="button"
            disabled={!selectedPrice}
            loading={redirectClicked}
            data-cta-type="button"
            onClick={handleRedirect}
          >
            Start Your 7-Day Trial
          </AppLoadingButton>
          <AppText color={theme.PRIMARY_COLOR} fontWeight={500} textAlign="center" style={{ marginTop: 20, marginBottom: 0 }}>
            In a rush? Activate with <DigitalWalletIcon />
          </AppText>
        </Container>
        <PrepaymentHeroImage />
        <DisclaimerText>
          Cancel anytime—we&apos;ll remind you before your trial ends. After your 7-day trial ends, you will be charged an annual subscription of $99.99 per year.
        </DisclaimerText>
      </main>
    </BasicLayout>
  );
};

const Container = styled(FlexDiv).attrs({
  direction: "column",
  align: "center",
})`
  padding: 24px;
  padding-top: 84px;
  padding-bottom: 84px;
  @media ${mobileSize} {
    padding-top: 32px;
    padding-bottom: 40px;
  }
`;

const PriceCard = styled.button.attrs({
  type: "button",
}) <{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 143px;
  height: 96px;
  border-radius: 8px;
  background-color: ${props => props.selected ? theme.SELECTED_COLOR : theme.WHITE_COLOR};
  box-shadow: 0px 2px 7px 0px #2C273824;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 20px;

  @media ${mobileSize} {
    width: 100%;
    max-width: 143px;
    height: 64px;
    font-size: 17px;
  }
`;

const DesktopBreak = styled.br`
  @media ${mobileSize} {
    display: none;
  }
`;

const CardsContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 56px;

  @media ${mobileSize} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
`;

const DisclaimerText = styled(AppText).attrs({
  color: theme.SLATE_COLOR,
  fontSize: 16,
  mobileFontSize: 13,
  textAlign: "center",
})`
  max-width: 900px;
  padding: 80px 24px;
  margin: 0 auto;

  @media ${laptopSize} {
    max-width: 780px;
  }

  @media ${mobileSize} {
    margin-top: 24px;
    padding-bottom: 86px;
  }
`;
